import React from "react"
import UtmHandler from "@atoms/UtmHandler/UtmHandler"

export const SiteContext = React.createContext()

const stuff = "temp"

const Provider = (props) => {
  UtmHandler()
  return (
    <SiteContext.Provider value={{ stuff }}>
      {props.children}
    </SiteContext.Provider>
  )
}
// eslint-disable-next-line import/no-anonymous-default-export
export default ({ element }) => <Provider>{element}</Provider>
