exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-templates-author-archive-js": () => import("./../../../src/templates/AuthorArchive.js" /* webpackChunkName: "component---src-templates-author-archive-js" */),
  "component---src-templates-blog-tag-js": () => import("./../../../src/templates/BlogTag.js" /* webpackChunkName: "component---src-templates-blog-tag-js" */),
  "component---src-templates-category-js": () => import("./../../../src/templates/Category.js" /* webpackChunkName: "component---src-templates-category-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/Page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-post-js": () => import("./../../../src/templates/Post.js" /* webpackChunkName: "component---src-templates-post-js" */),
  "component---src-templates-pro-tag-js": () => import("./../../../src/templates/ProTag.js" /* webpackChunkName: "component---src-templates-pro-tag-js" */),
  "component---src-templates-tag-js": () => import("./../../../src/templates/Tag.js" /* webpackChunkName: "component---src-templates-tag-js" */)
}

