import { useEffect } from "react"

const UtmHandler = () => {
  useEffect(() => {
    if (typeof window !== "undefined") {
      const queryString = window.location.search
      const urlParams = new URLSearchParams(queryString)

      const utmObject = {
        utm_id: urlParams.get("utm_id"),
        utm_source: urlParams.get("utm_source"),
        utm_medium: urlParams.get("utm_medium"),
        utm_campaign: urlParams.get("utm_campaign"),
        utm_term: urlParams.get("utm_term"),
        utm_content: urlParams.get("utm_content"),
      }

      Object.keys(utmObject).forEach((key) => {
        if (utmObject[key]) {
          localStorage.setItem(`almatalent_${key}`, utmObject[key])
        }
      })
    }
  }, [])

  return null
}

export default UtmHandler
